<div class="container">
	<div class="menu-header">
		<div>
			@if (currentUser(); as user) {
				<div>{{ user.fullName }}</div>
			}
			<div>{{ dateString }}</div>
		</div>
		<button
			type="button"
			mat-button
			(click)="closeNavbar()"
		>
			<mat-icon class="close-icon">close</mat-icon>
		</button>
	</div>
	<button
		type="button"
		class="navbar-button"
		mat-button
		(click)="logout()"
	>
		<span class="typo-headline">Logout</span>
	</button>
</div>
