<mat-sidenav-container>
	<mat-sidenav
		[autoFocus]="false"
		#sidenav
		mode="over"
		[opened]="opened"
		(closed)="onClose()"
		position="end"
	>
		<ng-content select="[menu]"></ng-content>
	</mat-sidenav>
	<mat-sidenav-content>
		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>
