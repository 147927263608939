import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { MainLayoutComponent } from '@mei/common/shared/layouts/main-layout/main-layout.component';

/** Root component. */
@Component({
	selector: 'meiw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet, MainLayoutComponent],
})
export class AppComponent {
	private readonly iconRegistry = inject(MatIconRegistry);

	public constructor() {
		this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
	}
}
