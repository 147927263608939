<header class="header">
	<img
		src="/assets/logo.png"
		class="logo"
		alt="logo"
		routerLink="/"
	/>
	<button
		type="button"
		(click)="toggleSideMenu()"
		class="icon-button"
	>
		<mat-icon>menu</mat-icon>
	</button>
</header>
