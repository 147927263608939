import {
	HttpEvent,
	HttpHandlerFn,
	HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AppUrlsConfig } from '../services/app-urls.config';

/**
 * Adds cookies with withCredentials option to requests HTTP header.
 * @param req Request http.
 * @param next Request http handler.
 */
export function interceptCreadential(
	req: HttpRequest<unknown>,
	next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
	const appUrlsConfigService = inject(AppUrlsConfig);

	if (appUrlsConfigService.isApplicationUrl(req.url)) {
		const newReq = req.clone({
			withCredentials: true,
		});

		return next(newReq);
	}

	// Do nothing.
	return next(req);
}
