import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { AppConfig } from '@mei/common/core/services/app.config';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { interceptCreadential } from '@mei/common/core/interceptors/credential.interceptor';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { WebAppConfig } from './app/features/shared/web-app.config';
import { provideWebAppRoutes } from './app/features/shared/web-route-paths';

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule),
		{ provide: AppConfig, useClass: WebAppConfig },
		provideAnimations(),
		provideHttpClient(withInterceptors([interceptCreadential])),
		provideRouter(appRoutes,
			withComponentInputBinding()),
		provideWebAppRoutes(),
		provideEnvironmentNgxMask(),
	],
})
	.catch(err => console.error(err));
