import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SideMenuService } from '@mei/common/core/ui-services/side-menu.service';
import { AsyncPipe } from '@angular/common';

import { HeaderComponent } from '../components/header/header.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { NavbarComponent } from '../components/navbar/navbar.component';

/** Main layout. */
@Component({
	selector: 'meic-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrl: './main-layout.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HeaderComponent, SideMenuComponent, AsyncPipe, NavbarComponent],
})
export class MainLayoutComponent {
	/** If side navbar is open. */
	protected isOpen = inject(SideMenuService).isOpen;
}
