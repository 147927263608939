import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';
import { UserService } from '@mei/common/core/services/user.service';
import { SideMenuService } from '@mei/common/core/ui-services/side-menu.service';
import { webRoutePaths } from 'projects/web/src/app/features/shared/web-route-paths';

/** Navbar component. */
@Component({
	selector: 'meic-navbar',
	styleUrl: './navbar.component.css',
	templateUrl: './navbar.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatButtonModule, MatListModule, MatButtonModule, MatIconModule],
})
export class NavbarComponent {
	private readonly sideMenuService = inject(SideMenuService);

	private readonly userService = inject(UserService);

	private readonly destroyRef = inject(DestroyRef);

	private readonly router = inject(Router);

	/** Get current user data. */
	protected currentUser = toSignal(this.userService.currentUser$, { initialValue: null });

	/** Close navbar. */
	protected closeNavbar(): void {
		this.sideMenuService.isOpen.set(false);
	}

	/** Logout. */
	protected logout(): void {
		this.userService.logout().pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe({
				complete: () => this.router.navigateByUrl(webRoutePaths.auth.children.login.url),
			});
	}

	/** Get header date string. */
	protected get dateString(): string {
		return new Date().toDateString();
	}
}
