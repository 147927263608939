import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideMenuService } from '@mei/common/core/ui-services/side-menu.service';

/** Side menu component. */
@Component({
	selector: 'meic-side-menu',
	styleUrl: './side-menu.component.css',
	templateUrl: './side-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatSidenavModule, MatIconModule],
})
export class SideMenuComponent {
	/** Whether menu is opened. */
	@Input()
	public opened: boolean | null = false;

	private menuService = inject(SideMenuService);

	/** Handle menu close. */
	protected onClose(): void {
		this.menuService.isOpen.set(false);
	}

	/** Handle menu open. */
	protected onOpen(): void {
		this.menuService.isOpen.set(true);
	}
}
