import { Portal } from '@angular/cdk/portal';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Side menu service. */
@Injectable({
	providedIn: 'root',
})
export class SideMenuService {

	/** Whether menu is opened. */
	public readonly isOpen = signal(false);

	/** Menu portal content. */
	public readonly menuPortal$ = new BehaviorSubject<Portal<unknown> | null>(null);

	/** Toggle the menu state. */
	public toggle(): void {
		this.isOpen.update(prev => !prev);
	}
}
