import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SideMenuService } from '@mei/common/core/ui-services/side-menu.service';

/** Header component. */
@Component({
	selector: 'meic-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatIconModule, RouterModule],
})
export class HeaderComponent {
	private readonly sideMenuService = inject(SideMenuService);

	/** Toggle side menu. */
	protected toggleSideMenu(): void {
		this.sideMenuService.toggle();
	}
}
